import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import ArrowLeft from "assets/icons/ArrowLeft.svg"
import ArrowRight from "assets/icons/ArrowRight.svg"

import { useSliderData } from "shared/Hooks/MainSlider/useSliderData"

import {
  ArrowWrapper,
  Container,
  ImageBox,
  NavigationWrapper,
  PaginationWrapper,
  SlideContainer,
  SlideWrapper,
  TextBox,
  Title,
} from "./styles"

import { OrangeLinkButton } from "components/Buttons"

const MainSlider = () => {
  const sliderData = useSliderData()

  return (
    <Container>
      <Swiper
        loop={true}
        speed={800}
        autoplay={{ delay: 4000 }}
        effect={"fade"}
        fadeEffect={{ crossFade: true }}
        pagination={{ el: ".mainPagination", clickable: true }}
        navigation={{
          nextEl: ".main-next",
          prevEl: ".main-prev",
        }}
      >
        {sliderData.map((slide, i) => (
          <SwiperSlide key={i}>
            <SlideContainer>
              <ImageBox>{slide.image}</ImageBox>
              <SlideWrapper>
                <TextBox>
                  <Title>{slide.title}</Title>
                  {slide.href.length > 0 && (
                    <OrangeLinkButton to={slide.href}>
                      czytaj więcej
                    </OrangeLinkButton>
                  )}
                </TextBox>
              </SlideWrapper>
            </SlideContainer>
          </SwiperSlide>
        ))}
      </Swiper>
      <NavigationWrapper>
        <ArrowWrapper className="main-prev">
          <img src={ArrowLeft} />
        </ArrowWrapper>
        <ArrowWrapper className="main-next">
          <img src={ArrowRight} />
        </ArrowWrapper>
      </NavigationWrapper>
      <PaginationWrapper>
        <div className="mainPagination"></div>
      </PaginationWrapper>
    </Container>
  )
}

export { MainSlider }
