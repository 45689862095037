import React from "react"

import { Consultation, Installation, Service } from "components/Icons"
import { NormalHeader, Paragraph } from "components/Typography"

export const useOfferCardData = () => {
  const data = [
    {
      icon: <Consultation width="87px" height="96px" color="#1371FB" />,
      title: <NormalHeader>Doradztwo</NormalHeader>,
      description: (
        <Paragraph>
          Nie wiesz, jaka klimatyzacja będzie najlepsza do twojego
          pomieszczenia? Doradzimy Ci&nbsp;w&nbsp;wyborze. Cenimy sobie
          najlepsze rozwiązania, dlatego pomożemy Ci wybrać klimatyzację, która
          spełni twoje wszystkie oczekiwania.
        </Paragraph>
      ),
      to: "/oferta#doradztwo",
    },
    {
      icon: <Installation width="107px" height="107px" color="#1371FB" />,
      title: <NormalHeader>Montaż</NormalHeader>,
      description: (
        <Paragraph>
          Nasi profesjonaliści zadbają, aby twoja klimatyzacja działała jak
          należy. Zajmujemy się kompleksowym montażem we&nbsp;wszystkich
          pomieszczeniach przez cały&nbsp;rok.
        </Paragraph>
      ),
      to: "/oferta#montaz",
    },
    {
      icon: <Service width="87px" height="96px" color="#1371FB" />,
      title: <NormalHeader>Serwis</NormalHeader>,
      description: (
        <Paragraph>
          Aby klimatyzacja działała prawidłowo i&nbsp;nie&nbsp;została
          siedliskiem bakterii, należy ją&nbsp;serwisować raz na&nbsp;jakiś
          czas. Klimalab zapewnia regularną konserwację jej systemów, dzięki
          czemu wydłuża jej żywotność i&nbsp;wydajność.
        </Paragraph>
      ),
      to: "/oferta#serwis",
    },
  ]
  return data
}
