import styled, { css } from "styled-components"
import { IconPath, IconPathStroke } from "shared/Styles/Icons/icons"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const CardContainer = styled(AnchorLink)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 340px;
  min-height: 500px;
  padding: 100px 70px 40px 70px;
  text-decoration: none;
  background-color: transparent;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.05);
    ${IconPath} {
      fill: #ffba33;
      transition: fill 0.3s ease;
    }
    ${IconPathStroke} {
      stroke: #ffba33;
      transition: stroke 0.3s ease;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    min-height: 400px;
    padding: 40px 30px 30px 30px;
    svg {
      width: 60px;
      height: 60px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    padding: 0;
    padding: 30px 20px 20px 20px;
    width: 33.33%;
    text-align: center;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    width: 100%;
    max-width: 100%;
    min-height: 0;
    margin-bottom: 30px;
    &:hover {
      box-shadow: 10px 10px 25px rgba(0, 0, 0, 0);
    }
  }
  @media screen and (max-width: ${p => p.theme.breakpoints.phoneBig}) {
    padding: 10px 10px 10px 10px;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
`
const margin = css`
  margin-bottom: 25px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    margin-bottom: 15px;
  }
`
export const IconContainer = styled.div`
  ${margin}
`
export const TitleContainer = styled.div`
  ${margin}
`
export const ArrowContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`
