import styled from "styled-components"

import { PageWrapper } from "shared/Styles/Wrappers"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .swiper-container {
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 812px) {
    height: calc(100vh - 50px);
    min-height: 375px;
  }
`
export const SlideWrapper = styled(PageWrapper)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 650px) {
    position: relative;
    justify-content: flex-start;
  }
`

export const ImageBox = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  width: 56.5vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 21px;
    background-color: ${props => props.theme.color.secondary};
    z-index: 2;
  }
  img {
    object-fit: cover;
    height: 100%;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`
export const SlideContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
  }
`

export const TextBox = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 140px;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    margin-top: 100px;
  }
  @media screen and (max-width: 650px) {
    align-items: center;
    justify-content: center;
    margin-top: -30px;
    max-width: 100%;
  }
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 45px;
  line-height: 1.2;
  color: #fff;
  text-align: right;
  margin-bottom: 50px;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    font-size: 45px;
    line-height: 1.2;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 40px;
  }
  @media screen and (max-width: 650px) {
    margin-bottom: 30px;
    text-align: center;
    font-size: 34px;
  }
`

export const Text = styled.div``

export const Button = styled.button``

export const PaginationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  height: 120px;
  width: calc(100% - 56.5vw);
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 0 80px;
  @media screen and (max-width: ${props => props.theme.breakpoints.tabletBig}) {
    padding: 0 40px;
    height: 80px;
  }
  @media screen and (max-width: 650px) {
    height: 60px;
    width: 100%;
    z-index: 5;
    justify-content: center;
  }
  div {
    .swiper-pagination-bullet {
      background: #bebebe;
      opacity: 1;
      width: 9px;
      height: 9px;
      display: inline-block;
      border-radius: 50%;
      &:not(:last-child) {
        margin-right: 50px;
      }
      &.swiper-pagination-bullet-active {
        background-color: ${props => props.theme.color.secondary};
      }
    }
  }
`

export const NavigationWrapper = styled(PageWrapper)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints.laptopStandard}) {
    top: 20px;
  }
  @media screen and (max-width: 650px) {
    display: none;
  }
`

export const ArrowWrapper = styled.div`
  margin-left: 20px;
  cursor: pointer;
`
