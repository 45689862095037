import React from "react"
import SEO from "components/seo"
import { OurOffer } from "sections/Homepage/OurOffer"
import { AboutCompany } from "sections/Homepage/AboutCompany"
import { Brands } from "sections/Homepage/Brands"
import { Realizations } from "sections/Homepage/Realizations"
import { MainSection } from "sections/MainSection"
import { MainSlider } from "sections/Homepage/MainSlider"

const IndexPage = () => {
  return (
    <>
      <SEO title="Instalacja i serwis klimatyzacji - Kraków" />
      <MainSection>
        <MainSlider />
      </MainSection>
      <AboutCompany />
      <OurOffer />
      <Brands />
      <Realizations />
    </>
  )
}

export default IndexPage
