import { graphql, useStaticQuery } from "gatsby"

export const useSliderPhotos = () => {
  const images = useStaticQuery(graphql`
    query {
      slide_1: file(relativePath: { eq: "images/Homepage/main001.png" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide_2: file(relativePath: { eq: "images/Homepage/main002.png" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide_3: file(relativePath: { eq: "images/Homepage/main003.png" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return images
}
