import styled from "styled-components"

export const Index = styled.p`
  position: relative;
  font-size: 14px;
  font-weight: 800;
  color: ${props => props.theme.color.secondary};
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-115%, -50%);
    height: 2px;
    width: 80px;
    background-color: ${props => props.theme.color.secondary};
    @media screen and (max-width: ${({theme})=>theme.breakpoints.phoneBig}){
      width: 20px;
    }
  }
`
