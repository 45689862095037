import React from "react"

import Image from "gatsby-image"
import { useSliderPhotos } from "./useSliderPhotos"

const useSliderData = () => {
  const images = useSliderPhotos()
  return [
    {
      title: (<>Profesjonalny i&nbsp;szybki&nbsp;montaż</>),
      image: <Image fluid={images.slide_1.childImageSharp.fluid} />,
      href: "",
    },
    {
      title: (<>Bogata oferta klimatyzatorów</>),
      image: <Image fluid={images.slide_3.childImageSharp.fluid} />,
      href: "",
    },
    {
      title: (<>Doradztwo<br></br>specjalistów<br></br>- darmowa wycena</>),
      image: <Image fluid={images.slide_2.childImageSharp.fluid} />,
      href: "",
    },
  ]
}

export { useSliderData }
