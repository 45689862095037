import React from "react"
import { MediumHeader, Paragraph } from "components/Typography"

import { OrangeLinkButton } from "components/Buttons"

import { withTheme } from "styled-components"
import { ArrowLong } from "components/Icons"
import { SectionIndex } from "components/SectionIndex"

import {
  ArrowWrapper,
  CasestudySliderWrapper,
  SlideImageBox,
  SlideTextBox,
  SlideTextWrapper,
  SlideImageWrapper,
  SlidesWrapper,
  TitleWrapper,
  BackgroundLogo,
  BlueBackground,
  ArrowContainer,
  CustomOverflowWrapper,
  SectionIndexWrapper,
  CustomPageWrapper,
} from "./styles"
import { PageWrapper } from "shared/Styles/Wrappers"
import { Swiper, SwiperSlide } from "swiper/react"

import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Brands = withTheme(({ theme }) => {
  const data = useStaticQuery(graphql`
    query {
      aux: file(relativePath: { eq: "images/Homepage/aux_png.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      samsung: file(relativePath: { eq: "images/Homepage/samsung.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gree: file(relativePath: { eq: "images/Homepage/gree.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rotenso: file(relativePath: { eq: "images/Homepage/rotens.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      haier: file(relativePath: { eq: "images/Homepage/haier.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sevra: file(relativePath: { eq: "images/Homepage/sevra.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      toshiba: file(relativePath: { eq: "images/Homepage/toshiba.png" }) {
        childImageSharp {
          fluid(maxWidth: 630, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const slides_content = [
    {
      title: "SAMSUNG",
      text: "",
      href: "/oferta",
      image: <Image fluid={data.samsung.childImageSharp.fluid} />,
    },
    {
      title: "AUX",
      text: "",
      href: "/oferta",
      image: <Image fluid={data.aux.childImageSharp.fluid} />,
    },
    {
      title: "GREE",
      text: "",
      href: "/oferta",
      image: <Image fluid={data.gree.childImageSharp.fluid} />,
    },
    {
      title: "ROTENSO",
      text: "",
      href: "/oferta",
      image: <Image fluid={data.rotenso.childImageSharp.fluid} />,
    },
    {
      title: "HAIER",
      text: "",
      href: "/oferta",
      image: <Image fluid={data.haier.childImageSharp.fluid} />,
    },
    {
      title: "SEVRA",
      text: "",
      href: "/oferta",
      image: <Image fluid={data.sevra.childImageSharp.fluid} />,
    },
    {
      title: "TOSHIBA",
      text: "",
      href: "/oferta",
      image: <Image fluid={data.toshiba.childImageSharp.fluid} />,
    },
  ]

  return (
    <CustomOverflowWrapper>
      <CustomPageWrapper>
        <SectionIndexWrapper>
          <SectionIndex>03</SectionIndex>
        </SectionIndexWrapper>
        <TitleWrapper>
          <MediumHeader fontSize="32px">Marki w naszej ofercie:</MediumHeader>
        </TitleWrapper>
        <CasestudySliderWrapper>
          <SlidesWrapper>
            <BlueBackground>
              <BackgroundLogo>
                <svg
                  width="78"
                  height="78"
                  viewBox="0 0 78 78"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M38.8948 75.6417C52.2937 71.6143 62.1196 60.3374 63.3702 47.539L63.3702 47.0915C63.3702 45.391 62.4769 43.6905 61.0477 42.706C56.8494 39.7526 48.7207 37.6046 41.4853 37.5151C39.8774 34.4721 38.4482 30.5342 37.9122 27.4912C45.0583 25.7012 53.187 28.1177 59.2612 33.8456C66.586 40.8266 68.6405 50.6715 64.7994 60.3374C63.7275 63.1119 65.0674 66.1548 67.7472 67.2288C70.5163 68.3028 73.5534 66.9603 74.6253 64.2753C76.4119 59.8004 77.3051 55.2359 77.3051 50.761C77.3944 41.4531 73.5534 32.7716 66.586 26.1487C56.4028 16.4828 41.7532 13.6188 30.0515 18.9888C28.265 19.7943 27.1037 21.4948 26.9251 23.3742C26.4784 28.5652 28.7116 36.6201 32.1953 42.9745C30.4088 45.8385 27.729 48.971 25.4065 51.0295C20.3149 45.6595 18.3497 37.4256 20.2256 29.2812C22.5481 19.4363 30.0515 12.7238 40.324 11.2024C43.1825 10.7549 45.237 8.06989 44.7903 5.11642C44.3437 2.16295 41.6639 0.193966 38.7161 0.641462C24.0666 2.78944 13.2581 12.5448 9.86373 26.7752C6.55866 40.4686 11.4716 54.6094 21.9228 62.0379C23.5307 63.1118 25.4958 63.2908 27.2824 62.4853C31.9273 60.3374 37.8229 54.3409 41.5746 48.1655C44.969 48.255 48.9887 48.971 52.0258 50.045C49.9713 57.1154 43.8077 63.0223 35.8577 65.3493C26.2104 68.2133 16.6525 65.0808 10.221 56.9364C8.43451 54.6094 5.04011 54.2514 2.71763 56.0414C1.55638 56.9364 0.931096 58.1894 0.752439 59.6214C0.752439 59.8004 0.752439 60.0689 0.752439 60.2479C0.752439 61.4114 1.10975 62.5749 1.91369 63.5593C11.025 75.2837 24.8706 79.7587 38.8948 75.6417Z"
                    fill="#126BED"
                  />
                </svg>
              </BackgroundLogo>
            </BlueBackground>
            <Swiper
              slidesPerView={1}
              loop={true}
              navigation={{ nextEl: ".brands-next", prevEl: ".brands-prev" }}
              pagination={{ el: ".pagination", type: "fraction" }}
              autoplay={{ delay: 6000 }}
            >
              {slides_content.map((slide, i) => (
                <SwiperSlide key={i}>
                  <SlideImageWrapper>
                    <SlideImageBox>{slide.image}</SlideImageBox>
                    <SlideTextBox>
                      <SlideTextWrapper>
                        <MediumHeader color="inherit">
                          {slide.title}
                        </MediumHeader>
                        <Paragraph color="inherit">{slide.text} </Paragraph>
                        <OrangeLinkButton to={slide.href}>
                          Czytaj więcej
                        </OrangeLinkButton>
                      </SlideTextWrapper>
                    </SlideTextBox>
                  </SlideImageWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlidesWrapper>
          <ArrowContainer>
            <ArrowWrapper
              height={"20px"}
              className="brands-prev"
              reversed={true}
            >
              <ArrowLong color={theme.color.gray} />
            </ArrowWrapper>
            <ArrowWrapper height={"20px"} className="brands-next">
              <ArrowLong color={theme.color.gray} />
            </ArrowWrapper>
          </ArrowContainer>
        </CasestudySliderWrapper>
      </CustomPageWrapper>
    </CustomOverflowWrapper>
  )
})

export { Brands }
