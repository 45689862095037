import React from "react"

import {
  CardContainer,
  IconContainer,
  TitleContainer,
  ArrowContainer,
} from "./styles"

import { ArrowLong } from "components/Icons"

const Card = ({ icon, title, children, linkTo }) => {
  return (
    <>
      <CardContainer to={linkTo}>
        <IconContainer>{icon}</IconContainer>
        <TitleContainer>{title}</TitleContainer>
        {children}
        <ArrowContainer>
          <ArrowLong width="80px" height="10px" color="transparent" />
        </ArrowContainer>
      </CardContainer>
    </>
  )
}

export { Card }
