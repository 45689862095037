import styled, { keyframes } from "styled-components"
import { OverflowWrapper, PageWrapper } from "shared/Styles/Wrappers"
import { Paragraph, MediumHeader } from "components/Typography"

export const CustomWrapper = styled(OverflowWrapper)`
  height: 100vh;
  position: relative;
  &:before {
    content: "";
    background: ${({ theme }) => theme.color.primary};
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    width: 56.5vw;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    height: unset;
    &:before {
      content: none;
    }
  }
`
export const CustomPageWrapper = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    flex-direction: column-reverse;
  }
`
export const SectionIndexWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 15%;
  display: ${({ mobile }) => (mobile ? "none" : "block")};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    right: 30px;
    top: 20%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: ${({ mobile }) => (mobile ? "block" : "none")};
    position: relative;
    margin-right: -50px;
  }
`
export const BackgroundLogo = styled.div`
  height: 90vh;
  width: auto;
  position: absolute;
  right: 75%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  svg {
    height: 100%;
    width: auto;
    path {
      fill: #126bed;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: none;
  }
`

export const Card = styled.div`
  padding: 80px 150px 80px 120px;
  max-width: 520px;
  z-index: 5;
  background: white;
  position: relative;
  box-shadow: 10px 5px 25px rgba(0, 0, 0, 0.1);
  ${Paragraph} {
    margin: 22px 0;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    padding: 80px 80px 60px 80px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    height: auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    padding: 43px 72px;
    max-width: 400px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    box-shadow: none;
    width: 100%;
    max-width: unset;
    padding: 30px;
    background: ${({ theme }) => theme.color.white};
    ${Paragraph} {
      font-size: 14px;
    }
    ${MediumHeader} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`
export const ImageWrapper = styled.div`
  z-index: 1;
  flex-grow: 1;
  z-index: 3;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    width: auto;
    max-height: 380px;
    overflow: hidden;
    .gatsby-image-wrapper {
      width: 550px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    width: 100%;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
`
const smiglo = keyframes`
0% {
    transform: rotate(0);
}
100% {
    transform: rotate(-360deg);
}
`
export const LogoWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 10%;
  width: 20%;
  height: auto;
  @media screen and (max-width: 1366px) {
    top: 20px;
    right: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    height: 60px;
    top: 30px;
    right: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: none;
  }
  svg {
    transform-origin: 39.5px 40.2px;
    animation: ${smiglo} 2s infinite linear;
  }
`
