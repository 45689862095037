import React from "react"
import { CardsWrapper } from "./styles"
import { useOfferCardData } from "shared/Hooks/OurOffer"
import { Card } from "sections/Homepage/OurOffer/Card"

const OfferCards = () => {
  const data = useOfferCardData()
  return (
    <CardsWrapper>
      {data.map((card, index) => (
        <Card key={index} icon={card.icon} title={card.title} linkTo={card.to}>
          {card.description}
        </Card>
      ))}
    </CardsWrapper>
  )
}

export { OfferCards }
