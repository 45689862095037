import {OrangeLinkButton}        from "components/Buttons"
import {ArrowLong}               from "components/Icons"
import {SectionIndex}            from "components/SectionIndex"
import {MediumHeader, Paragraph} from "components/Typography"
import React, {useState}         from "react"
import {Parallax}                from "react-scroll-parallax"

import {useRealisationsData} from "shared/Hooks/Realisations"
import {PageWrapper}         from "shared/Styles/Wrappers"

import {withTheme}           from "styled-components"
import {Swiper, SwiperSlide} from "swiper/react"

import {ArrowContainer, ArrowWrapper, BackgroundIndex, BackgroundLogo, CasestudySliderWrapper, CustomOverflowWrapper, SectionIndexWrapper, SlideImageBox, SlideImageWrapper, SlidesWrapper, SlideTextBox, SlideTextWrapper, TitleWrapper,} from "./styles"

export const Realizations = withTheme(({theme}) => {
  const data = useRealisationsData()
  const [index, setIndex] = useState(0)

  const max_words = 120

  return (
    <CustomOverflowWrapper>
      <BackgroundLogo>
        <Parallax className="custom-class" x={[-20, 20]}>
          <svg
            width="2724"
            height="353"
            viewBox="0 0 2724 353"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.2173 0H191.276L167.743 111.471L282.991 0H415.134L263.079 145.397L350.571 351.982H226.876L175.587 219.307L137.574 255.051L117.662 351.982H0L74.2173 0Z"
              fill="#126BED"
            />
            <path
              d="M457.975 0H575.034L519.521 262.926H669.766L651.061 352.588H383.758L457.975 0Z"
              fill="#126BED"
            />
            <path
              d="M756.051 0H873.11L798.892 352.588H681.834L756.051 0Z"
              fill="#126BED"
            />
            <path
              d="M908.108 0H1016.72L1061.97 162.36L1174.81 0H1290.66L1216.44 352.588H1099.38L1130.76 202.95L1068.01 292.006H1007.06L982.325 202.344L950.949 351.982H833.891L908.108 0Z"
              fill="#126BED"
            />
            <path
              d="M1444.52 0H1567.01L1627.96 352.588H1510.9L1498.23 282.313H1402.89L1361.26 352.588H1235.15L1444.52 0ZM1500.64 200.527L1484.95 115.106L1434.27 200.527H1500.64Z"
              fill="#126BED"
            />
            <path
              d="M1734.76 0H1851.81L1796.3 262.926H1946.55L1927.84 352.588H1659.94L1734.76 0Z"
              fill="#126BED"
            />
            <path
              d="M2139.63 0H2262.12L2323.06 352.588H2206L2193.33 282.313H2098L2056.36 352.588H1930.86L2139.63 0ZM2195.75 200.527L2180.66 115.106L2129.98 200.527H2195.75Z"
              fill="#126BED"
            />
            <path
              d="M2430.47 0H2630.79C2659.76 0 2682.69 7.26985 2698.98 21.2037C2715.27 35.1376 2723.72 53.9181 2723.72 77.5451C2723.72 81.7859 2723.11 89.0557 2721.3 98.143C2718.29 115.106 2711.65 130.252 2703.2 142.974C2694.75 155.696 2683.29 165.389 2670.62 173.265C2682.08 178.717 2690.53 185.381 2695.96 193.863C2701.39 202.344 2704.41 212.643 2704.41 225.365C2704.41 232.635 2703.8 239.905 2701.99 246.569C2695.36 281.101 2680.27 307.151 2656.74 325.326C2633.81 343.501 2601.23 352.588 2559.59 352.588H2355.65L2430.47 0ZM2543.91 272.014C2555.97 272.014 2565.02 269.59 2571.06 264.744C2577.09 259.897 2581.32 252.022 2583.73 241.117C2584.33 237.482 2584.94 235.059 2584.94 233.241C2584.94 219.307 2575.28 212.643 2555.37 212.643H2496.24L2483.57 272.014H2543.91V272.014ZM2570.45 138.127C2581.92 138.127 2590.97 135.704 2596.4 131.463C2602.43 126.617 2606.05 119.953 2607.87 109.654C2608.47 106.019 2609.07 102.99 2609.07 101.172C2609.07 87.8441 2599.42 81.18 2580.71 81.18H2524.6L2512.53 138.127H2570.45V138.127Z"
              fill="#126BED"
            />
          </svg>
        </Parallax>
      </BackgroundLogo>

      <PageWrapper>
        <SectionIndexWrapper>
          <SectionIndex>04</SectionIndex>
        </SectionIndexWrapper>
        <TitleWrapper>
          <MediumHeader fontSize="32px" color="white">
            Realizacje
          </MediumHeader>
          <BackgroundIndex>{data[index].index}</BackgroundIndex>
        </TitleWrapper>
        <CasestudySliderWrapper>
          <SlidesWrapper>
            <Swiper
              slidesPerView={1}
              loop={true}
              onSlideChange={argument => setIndex(argument.realIndex)}
              spaceBetween={80}
              navigation={{
                nextEl: ".realisations-next",
                prevEl: ".realisations-prev",
              }}
              autoplay={{
                delay: 6000,
              }}
              pagination={{el: ".pagination", type: "fraction"}}
            >
              {data.map((slide, i) => (
                <SwiperSlide key={i}>
                  <SlideImageWrapper>
                    <SlideTextBox>
                      <SlideTextWrapper>
                        <MediumHeader color="inherit">
                          {slide.title}
                        </MediumHeader>
                        <Paragraph color="inherit">
                          {slide.desc_short.length > max_words
                           ? slide.desc_short.substring(0, max_words - 3) +
                             "..."
                           : slide.desc_short}
                        </Paragraph>
                        <OrangeLinkButton to={slide.href}>
                          Czytaj więcej
                        </OrangeLinkButton>
                      </SlideTextWrapper>
                    </SlideTextBox>
                    <SlideImageBox>{slide.images[1]}</SlideImageBox>
                  </SlideImageWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
          </SlidesWrapper>
          <ArrowContainer>
            <ArrowWrapper
              height={"20px"}
              className="realisations-prev"
              reversed={true}
            >
              <ArrowLong color={theme.color.white}/>
            </ArrowWrapper>
            <ArrowWrapper height={"20px"} className="realisations-next">
              <ArrowLong color={theme.color.white}/>
            </ArrowWrapper>
          </ArrowContainer>
        </CasestudySliderWrapper>
      </PageWrapper>
    </CustomOverflowWrapper>
  )
})
