import styled from "styled-components"

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    margin-top: 20px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
`
