import styled from "styled-components"
import { Paragraph, MediumHeader } from "components/Typography"
import { IconPathStroke } from "shared/Styles/Icons"
import { OverflowWrapper } from "shared/Styles/Wrappers"
import { PageWrapper } from "shared/Styles/Wrappers"

export const CustomPageWrapper = styled(PageWrapper)`
  z-index: 3;
`

export const CustomOverflowWrapper = styled(OverflowWrapper)`
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    margin-top: 60px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: block;
    height: unset;
    padding: 50px 10px 0;
    &:before {
      position: absolute;
      content: " ";
      background: ${({ theme }) => theme.color.white};
      width: calc(100% - 60px);
      height: 100%;
      left: 30px;
      top: 0;
      z-index: -2;
    }
  }
`
export const SectionIndexWrapper = styled.div`
  text-align: right;
  & > * {
    margin-left: auto;
    display: inline-block;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin-bottom: 0px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    margin-bottom: -50px;
    margin-right: 10px;
  }
`
export const ArrowContainer = styled.div`
  display: flex;
  margin-top: 20px;
`
export const ArrowWrapper = styled.div`
  cursor: pointer;
  width: 42px;
  transform: ${({ reversed }) =>
    reversed ? "rotate(180deg) translateY(4px)" : "none"};
  display: inline-block;
  &:hover {
    ${IconPathStroke} {
      stroke: ${({ theme }) => theme.color.primary};
    }
  }
  &:last-child {
    margin-left: 20px;
  }
`

export const CasestudySliderWrapper = styled.div`
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const SlideImageBox = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    flex-grow: 1;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    padding-top: 0;
    width: 100%;
    height: 250px;
  }
  .gatsby-image-wrapper {
    width: 630px;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletBig}) {
      width: 410px;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletSmall}) {
      width: 100%;
    }
  }
`
export const SlidesWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    margin: 0 -10px;
    width: calc(100% + 20px);
  }
`
export const BackgroundLogo = styled.div`
  position: absolute;
  height: 120%;
  width: auto;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 1;
  svg {
    height: 100%;
    width: auto;
    path {
      fill: #126bed;
    }
  }
`
export const BlueBackground = styled.div`
  width: 620px;
  height: 100%;
  background: ${({ theme }) => theme.color.primary};
  position: absolute;
  z-index: -2;
  overflow: hidden;
  right: 0;
  top: 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    width: 456px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    width: calc(40% + 100px);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    width: 100%;
    height: calc(100% - 250px);
    bottom: 0;
    top: unset;
  }
`
export const SlideTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 100px;
  position: relative;
  overflow: hidden;
  color: white;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    padding: 50px 90px 50px 20px;
    margin-left: auto;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    padding: 50px 60px 50px 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    margin: unset;
    padding: 56px 46px 56px 30px;
    align-items: flex-start;
    max-width: unset;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phoneSmall}) {
    padding: 30px 20px;
  }
`

export const SlideTextWrapper = styled.div`
  max-width: 270px;
  width: 100%;
  ${Paragraph} {
    margin: 20px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    max-width: 200px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    max-width: 250px;
    ${MediumHeader} {
      font-size: 22px;
    }
  }
`
export const SlideImageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    flex-direction: column;
  }
`

export const TitleWrapper = styled.div`
  transform: translateY(45px);
  z-index: 5;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    transform: none;
  }
`
