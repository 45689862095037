import React from "react"
import { Index } from "./styles"

const SectionIndex = ({ children }) => {
  return (
    <>
      <Index>{children}</Index>
    </>
  )
}

export { SectionIndex }
