import styled from "styled-components"
import { Paragraph, MediumHeader } from "components/Typography"
import { IconPathStroke } from "shared/Styles/Icons"

export const CustomOverflowWrapper = styled.section`
  position: relative;
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.color.primary};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: block;
    height: unset;
    padding-top: 0;
    margin-top: 80px;
    background: transparent;
  }
`
export const SectionIndexWrapper = styled.div`
  text-align: right;
  & > * {
    margin-left: auto;
    display: inline-block;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin-bottom: -40px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    right: 40px;
    top: 30px;
    z-index: 8;
    position: absolute;
  }
`
export const ArrowContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    margin-top: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin-top: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    position: absolute;
    top: 320px;
    left: 30px;
    margin-top: 0;
    z-index: 15;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phoneSmall}) {
    left: 20px;
  }
`
export const ArrowWrapper = styled.div`
  cursor: pointer;
  width: 42px;
  transform: ${({ reversed }) =>
    reversed ? "rotate(180deg) translateY(4px)" : "none"};
  display: inline-block;
  &:hover {
    ${IconPathStroke} {
      stroke: ${({ theme }) => theme.color.secondary};
    }
  }
  &:last-child {
    margin-left: 20px;
  }
`
export const BackgroundIndex = styled.div`
  position: absolute;
  font-size: 220px;
  color: #1261d5;
  top: 0;
  left: 80px;
  letter-spacing: -0.15em;
  z-index: 1;
  font-weight: bold;
  top: 70%;
  transform: translateY(-50%);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    left: 30px;
    top: 60%;
    font-size: 180px;
  }
`
export const CasestudySliderWrapper = styled.div`
  position: relative;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptopStandard}) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: flex;
    z-index: 12;
    background: ${({ theme }) => theme.color.white};
  }
`

export const SlidesWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`
export const SlideImageBox = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    flex-grow: 1;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    padding-top: 0;
    width: 100%;
    height: 360px;
    padding-bottom: 70px;
    background: ${({ theme }) => theme.color.primary};
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      /* spacing between slides */
      right: -80px;
      top: 0;
      width: 80px;
      height: 100%;
      background: ${({ theme }) => theme.color.primary};
    }
  }
  .gatsby-image-wrapper {
    width: 680px;
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.laptopStandard}) {
      height: 500px;
    }
    @media screen and (max-width: 1367px) {
      height: 400px;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletBig}) {
      width: 450px;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.tabletSmall}) {
      min-width: 300px;
      width: 100%;
      height: 400px;
    }
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.phoneBig}) {
      width: 100%;
      height: 290px;
    }
  }
`
export const BackgroundLogo = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  svg {
    width: 150%;
    height: auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    display: none;
  }
`
export const SlideTextBox = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    flex-grow: 1;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    flex-grow: 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    margin: unset;
    padding: 0;
    align-items: flex-start;
    max-width: unset;
    background: transparent;
  }
`

export const SlideTextWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  padding: 65px;
  ${Paragraph} {
    margin: 20px 0;
  }
  @media screen and (max-width: 1367px) {
    ${MediumHeader} {
      font-size: 24px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    padding: 50px 20px 50px 50px;
    margin: 0 auto;
    max-width: 370px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    padding: 30px 30px;
    margin: 0;
    max-width: 370px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    padding: 30px 30px;
    margin-left: 0;
    ${MediumHeader} {
      font-size: 22px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phoneSmall}) {
    padding: 30px 20px;
  }
`
export const SlideImageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    flex-direction: column-reverse;
    align-items: unset;
  }
`

export const TitleWrapper = styled.div`
  z-index: 5;
  position: relative;
  margin: 20px 0 20px 60px;
  ${MediumHeader} {
    z-index: 3;
    position: relative;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    transform: none;
    margin: unset;
    padding: 20px 0 20px 20px;
    background: ${({ theme }) => theme.color.primary};
  }
`
