import styled from "styled-components"

export const SectionTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SectionContainer = styled.div`
  margin-top: 80px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletBig}) {
    margin-top: 60px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    margin-top: 40px;
  }
`
export const BackgroundImageWrapper = styled.div`
  position: relative;
`
export const BackgroundImage = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-40%);
  width: 60%;
  z-index: -1;
`
