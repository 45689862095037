import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import { PageWrapper } from "shared/Styles/Wrappers"
import { MediumHeader } from "components/Typography"
import { SectionIndex } from "components/SectionIndex"

import { OfferCards } from "./OfferCards"

import {
  SectionTitleWrapper,
  SectionContainer,
  BackgroundImage,
  StyledPageWrapper,
  BackgroundImageWrapper,
} from "./styles"

const OurOffer = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/Homepage/logo_gray_big.png" }) {
        childImageSharp {
          fluid(maxWidth: 1332, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <BackgroundImageWrapper>
        <BackgroundImage>
          <Image fluid={query.file.childImageSharp.fluid} />
        </BackgroundImage>
        <PageWrapper>
          <SectionContainer>
            <SectionTitleWrapper>
              <MediumHeader>Nasza oferta</MediumHeader>
              <SectionIndex>02</SectionIndex>
            </SectionTitleWrapper>
            <OfferCards />
          </SectionContainer>
        </PageWrapper>
      </BackgroundImageWrapper>
    </>
  )
}

export { OurOffer }
